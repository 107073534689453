import React from 'react';
import { BlogEntry } from './BlogEntry';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import MathJax from 'react-mathjax';
import ImageGallery from 'react-image-gallery';
import { CodeBlock } from './CodeBlock';

const Inline = ({children}) => {
    return (
        <MathJax.Node inline formula={children}></MathJax.Node>
    )
}
const Formula = ({children}) => {
    return (
        <MathJax.Node formula={children} className="overflow-x-auto"></MathJax.Node>
    )
}

const Sep = () => {
    return (
        <div className="m-2 flex justify-center">
        <div className="h-1 rounded w-1/2"  style={{
            background: 'linear-gradient(to right, #38b2ac, #4299e1)'
        }}></div></div>
    )
}

let gallery = [
    'https://tdbr.de/blog/out_-0.5_-0.5_-0.5.png',
    'https://tdbr.de/blog/out_-1.0_1.0_1.0.png',
    'https://tdbr.de/blog/out_0.0_0.0_0.0.png',
    'https://tdbr.de/blog/out_0.0_0.0_1.0.png',
    'https://tdbr.de/blog/out_0.0_1.0_0.0.png',
    'https://tdbr.de/blog/out_0.0_1.0_1.0.png',
    'https://tdbr.de/blog/out_0.5_0.5_0.5.png',
    'https://tdbr.de/blog/out_1.0_-1.0_1.0.png',
    'https://tdbr.de/blog/out_1.0_0.0_0.0.png',
    'https://tdbr.de/blog/out_1.0_0.0_1.0.png',
    'https://tdbr.de/blog/out_1.0_1.0_-1.0.png',
    'https://tdbr.de/blog/out_1.0_1.0_0.0.png',
    'https://tdbr.de/blog/out_1.0_1.0_1.0.png'
]
gallery = gallery.map(e => { return {original: e, thumbnail: e} })

export class Blog extends React.Component {
    render() {
        return (
            <div>
                <div className="p-3 m-3">
                    <Link className="no-underline text-teal-800 font-bold hover:text-teal-700" to="/"><i className="fas fa-backward"></i> Zurück zur Startseite</Link>
                </div>
                <div className="container mx-auto">
                    <BlogEntry id="diversifikation" title="Optimales Risiko dank Diversifikation" subtitle="04.09.2019 - Tim David Brachwitz">
                        <a target="_blank" href="https://medium.com/@david.brachwitz/diversifikation-aea350bc90f5" className="no-underline hover:text-teal-600">
                            Mein erster Blogartikel auf Medium über den Effekt der Diversifikation
                        </a>
                    </BlogEntry>
                    <div className="h-24"></div>
                    {/*
                    <BlogEntry title="Was ist eine Risikoprämie?" subtitle="Wie man einen Untertitel macht">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.
                    </BlogEntry>
                    <BlogEntry title="Meine Bachelorarbeit - Sicherheit und Stabilität in Distributed Ledger Technologies" subtitle="Wie man einen Untertitel macht">
                    Hallo. Ich bin ein kleiner Blindtext. Und zwar schon so lange ich denken kann. Es war nicht leicht zu verstehen, was es bedeutet, ein blinder Text zu sein: Man macht keinen Sinn. Wirklich keinen Sinn. Man wird zusammenhangslos eingeschoben und rumgedreht – und oftmals gar nicht erst gelesen. Aber bin ich allein deshalb ein schlechterer Text als andere? Na gut, ich werde nie in den Bestsellerlisten stehen. Aber andere Texte schaffen das auch nicht. Und darum stört es mich nicht besonders blind zu sein. Und sollten Sie diese Zeilen noch immer lesen, so habe ich als kleiner Blindtext etwas geschafft, wovon all die richtigen und wichtigen Texte meist nur träumen.
                    </BlogEntry>
                    <BlogEntry title="Symbolische Wahrscheinlichkeitstheorie" subtitle="the characteristic function of the standard normal distribution">
                        <a target="_blank" href="https://gist.github.com/LuxXx/9f7aad04e5e05ea1f64f7c3e80f4a10c">https://gist.github.com/LuxXx/9f7aad04e5e05ea1f64f7c3e80f4a10c</a>
                    </BlogEntry>
                    <BlogEntry title="Investition und Finanzierung - TU Berlin" subtitle="Wie man einen Untertitel macht">
                        <a target="_blank" href="https://if.tdbr.de/">if.tdbr.de/</a>
                    </BlogEntry>
                    <BlogEntry title="RGN Spielerstatistiken" subtitle="Wie man einen Untertitel macht">
                        <a target="_blank" href="http://rgn.tdbr.de/">rgn.tdbr.de/</a>
                    </BlogEntry>
                    <BlogEntry title="Mintos Kontostand" subtitle="Wie man einen Untertitel macht">
                    </BlogEntry>
                    <BlogEntry title="BICCX" subtitle="Wie man einen Untertitel macht">
                    </BlogEntry>
                    <BlogEntry title="St. Petersburg" subtitle="Wie man einen Untertitel macht">
                    </BlogEntry>
                    <BlogEntry title="RageMP Spielerstatistiken" subtitle="Wie man einen Untertitel macht" last={true}>
                        <a target="_blank" href="https://gist.github.com/LuxXx/d80dbf48f36c7e362815bb545507385d">https://gist.github.com/LuxXx/d80dbf48f36c7e362815bb545507385d</a>
                    </BlogEntry>


 
                    CryptoCurrency Correlation Chart
                    Der Leverage Effekt
                    */}
                    <MathJax.Provider>
                    
                    <BlogEntry id="portfoliovolatilitaet" title="Die Portfoliovolatilität und Korrelationen zwischen einzelnen Wertpapieren" subtitle="19.09.2019 - Tim David Brachwitz">
                        <div className="italic mb-3 text-sm text-gray-1000">
                            Hey, für diesen Artikel ist es hilfreich und wichtig, dass du weißt was Erwartungswert, Varianz und Korrelation bedeuten.
                            Hast du schon <a href="#erwartungswert-varianz" className="underline">diesen Artikel</a> dazu gelesen?
                        </div>
                        <div>
                            Jedes Wertpapier, das an der Börse gehandelt wird, erzielt eine - hoffentlich positive - Rendite.
                            Außerdem unterliegt jedes Wertpapier Wertschwankungen.
                        </div>
                        
                        <div>
                            In diesem Artikel betrachten wir wie sich diese Wertschwankungen also Volatilitäten unter verschiedenen Korrelationensszenarien in einem Portfolio verhalten.
                            <br/>
                            Ein Portfolio ist ein Gewichtsvektor <Inline>{'w = (w_1, w_2, …, w_N)'}</Inline> der Länge <Inline>N</Inline> deren Summe 100% ergibt (<Inline>{'\\sum_{i=1}^N w_i=1, w_i>0'}</Inline>).
                            Zum Beispiel ist <Inline>{'w = (0.3, 0.6, 0.1)'}</Inline> ein Portfolio mit 30% in Asset 1, 60% in Asset 2 und 10% in Asset 3.
                            Ein Portfolio enthält also <Inline>N</Inline> verschiedene Wertpapiere.
                        </div>
                        <div>
                            Die Rendite des Gesamtportfolios <Inline>{'R_p'}</Inline> ist die Summe der Gewichte multipliziert mit den Einzelrenditen <Formula>{'R_p = w_1 \\cdot R_1 + w_2 \\cdot R_2 + \\ldots + w_N \\cdot R_N\\text{.}'}</Formula>
                            
                            Streng genommen wissen wir gar nicht wie sich die Renditen verhalten, daher können wir sie als Zufallsvariablen modellieren.
                            Das heißt, dass wir den eigentlichen Wert von <Inline>{'R_1, R_2, \\ldots'}</Inline> dem Zufall überlassen.
                            Die Rendite <Inline>{'R_1'}</Inline> von Asset 1 könnte +3% aber auch -5% sein.
                            Das genaue Verhalten des Zufalls, spielt in dieser Betrachtung erstmal keine Rolle.

                            Wir können die Rendite nun mittels Erwartungswert schätzen.
                            Dann ist die zu erwartende Rendite
                            <Formula>
                                {'\\operatorname{E}(R_p) = \\operatorname{E}(w_1 R_1 + w_2 R_2 + \\ldots + w_N R_N) = w_1 \\operatorname{E}(R_1) + w_2 \\operatorname{E}(R_2) + \\ldots + w_N \\operatorname{E}(R_N)\\text{.}'}
                            </Formula>
                        </div>

                        <div>
                            Die Volatilität eines Wertpapiers <Inline>{'i'}</Inline> können wir mithilfe der Standardabweichung <Inline>
                                {'\\sigma_i = \\sqrt{\\operatorname{Var}(R_i)}'}
                            </Inline> messen. 

                            Die Varianz bzw. Standardabweichung gibt an wie stark die Rendite schwankt.
                            Beispielsweise haben Staatsanleihen in Kontrast zu Aktien in der Regel eine recht niedrige Volatilität.

                            Als nächstes Ziel gilt es nach der Portfoliorendite die zugehörige Portfoliovolatilität für ein Portfolio zu errechnen.
                            Aus didaktischen Gründen besteht unser Portfolio erstmal nur aus zwei Aktien.
                            Wir nutzen eine allbekannte Rechenregeln für Varianzen (Lineare Transformation).
                            
                            <Formula>
                                {'\\operatorname{Var}(aX + bY) = a^2\\operatorname{Var}(X) + b^2\\operatorname{Var}(Y) + 2ab\\operatorname{Cov}(X,Y)'}
                            </Formula>

                            Rechnen wir zuerst die Varianz der Portfoliorendite aus.
                            <Formula>
                                {'\\sigma_p^2 = \\operatorname{Var}(R_p) = \\operatorname{Var}(w_1 \\cdot R_1 + w_2 \\cdot R_2) = w_1^2 \\cdot \\underbrace{\\operatorname{Var}(R_1)}_{\\sigma_1^2} + w_2^2\\cdot\\underbrace{\\operatorname{Var}(R_2)}_{\\sigma_2^2} + 2w_1w_2\\operatorname{Cov}(R_1, R_2)'}
                            </Formula>
                            Die Portfoliovolatilität ist die Wurzel aus der Varianz der Portfoliorendite.
                            Wir nennen <Inline>{'\\operatorname{Cov}(R_i,R_j)=\\sigma_{ij}'}</Inline> die Kovarianz zwischen <Inline>{'i'}</Inline> und <Inline>{'j'}</Inline>. 
                            Im nächsten Schritt ziehen wir die Wurzel und erhalten die eigentliche Portfoliovolatilität
                            <Formula>
                                {'\\sigma_p = \\sqrt{\\operatorname{Var}(R_p)} = \\sqrt{\\operatorname{Var}(w_1 \\cdot R_1 + w_2 \\cdot R_2)} = \\sqrt{w_1^2 \\sigma_1^2 + w_2^2 \\sigma_2^2 + 2w_1w_2\\underbrace{\\operatorname{Cov}(R_1, R_2)}_{\\sigma_{12}}}'}
                            </Formula>
                            <Formula>
                                {'\\sigma_p = \\sqrt{w_1^2 \\sigma_1^2 + w_2^2 \\sigma_2^2 + 2w_1w_2\\sigma_{12}}'}
                            </Formula>

                            {
                                // Kurze Erklärung zu Kovarianz
                            }
                            Wir wissen, dass der Korrelationskoeffizient so definiert ist:
                            <Formula>
                                {'\\rho_{ij}=\\frac{\\operatorname{Cov}(R_i, R_j)}{\\sigma_i\\sigma_j}=\\frac{\\sigma_{ij}}{\\sigma_i\\sigma_j}'}
                            </Formula>
                            Umgestellt nach <Inline>{'\\sigma_{ij}'}</Inline>
                            <Formula>
                                {'\\sigma_{ij}=\\rho_{ij}\\sigma_i\\sigma_j'}
                            </Formula>
                            und eingesetzt erhalten wir
                            <Formula>
                                {'\\sigma_p = \\sqrt{w_1^2 \\sigma_1^2 + w_2^2 \\sigma_2^2 + 2w_1w_2\\rho_{12}\\sigma_1\\sigma_2}'}
                            </Formula>

                            Diese Formel beschreibt die Volatilität eines 2-Wertpapier-Portfolios.
                            Bereits in der Formel können wir sehen, dass ein hoher Korrelationskoeffizient <Inline>{'\\rho_{ij}'}</Inline> die Portfoliovolatilität erhöht.

                            <Sep/>
                            <div className="font-bold flex justify-center text-lg my-3">
                                Wie Korrelationen die Portfoliovolatilität beeinflussen.
                                <br/>
                                (Oder reißerisch wie Diversifikation das Risiko beeinflusst)
                            </div>
                                Schauen wir uns mal ein Beispiel von zwei Aktien an.
                            <div className="flex justify-center my-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="p-2"></th>
                                            <th className="p-2 border-b">Rendite <Inline inline>{'\\operatorname{E}(R_i)'}</Inline></th>
                                            <th className="p-2 border-b">Volatilität <Inline inline>{'\\sigma_i'}</Inline></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="p-2 font-semibold border-r">Asset 1</td>
                                            <td className="p-2 text-right">0.1</td>
                                            <td className="p-2 text-right">3</td>
                                        </tr>
                                        <tr>
                                            <td className="p-2 font-semibold border-r">Asset 2</td>
                                            <td className="p-2 text-right">0.3</td>
                                            <td className="p-2 text-right">4</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            Zeichnen wir die Aktien mal in ein Rendite-Risikoprofil ein.
                            Asset 1 ist der linke blaue Punkt und Asset 2 der rechte.
                            <div>
                                <div className="flex justify-center">
                                    <img src="https://tdbr.de/blog/base.png" alt="positiv korreliert"/>
                                </div>
                            </div>
                            
                            <Sep/>
                            <div className="my-3">
                                Jetzt wollen wir uns mal klar machen was mit dem Portfoliovolatilität passiert, wenn wir den Korrelationskoeffizienten auf verschiedene werte setzen.
                                Wir gehen erstmal die Spezialfälle <Inline inline>{'\\rho_{12}=0,-1, 1'}</Inline> durch und diskutieren ihre Auswirkungen.
                                <div>
                                    <div className="font-bold text-xl my-3">
                                        Fall <Inline inline>{'\\rho_{12}=0'}</Inline>
                                    </div>
                                    Wenn <Inline inline>{'\\rho_{12}=0'}</Inline>, sind die Assets unkorreliert.
                                    Das bedeutet, dass es keinen (linearen) Zusammenhang zwischen den Renditen der beiden Assets gibt.

                                    Wir können nun alle Portfolien einzeichnen, die aus diesen beiden Aktien gebaut werden können.
                                    Wenn wir dies tun, erhalten wir die eine rote Linie, die aus unendlich vielen Punkten besteht.
                                    Jeder Punkt ist genau ein Portfolio.
                                    Jede Aufteilung von 100% auf Asset 1 und Asset 2 findet sich irgendwo auf dieser Linie wieder.
                                    Das spannende ist, wir sehen, dass es ein Portfolio gibt, dass ein minimales Risiko bietet.
                                    Und das Portfolio performed gar nicht so schlecht!
                                    Ab sofort zeichnen wir dieses Portfolio auf den folgenden Grafiken als schwarzen Punkt ein.

                                    <div className="flex justify-center">
                                        <img src="https://tdbr.de/blog/out_101.png" alt="unkorreliert"/>
                                    </div>

                                    Das Portfolio, das wir in schwarz gezeichnet haben, nennen wir optimales Portfolio.
                                    Wir berechnen nun analytisch das optimale Portfolio. Wir suchen das Portfolio mit minimalem Risiko.
                                    Das heißt wir möchten die Volatilitätsfunktion minimieren.
                                    Es macht analytisch keinen Unterschied, ob wir <Inline inline>{'\\sigma_p'}</Inline> oder <Inline inline>{'\\sigma_p^2'}</Inline> minimieren.
                                    Da <Inline inline>{'\\rho_{12}=0'}</Inline> gilt
                                    <Formula>{'\\sigma_p = \\sqrt{w_1^2 \\sigma_1^2 + w_2^2 \\sigma_2^2}'}</Formula>

                                    Das Optimierungsproblem kann man wie folgt definieren.
                                    <Formula>
                                        {'\\min_{w_1, w_2} \\sigma_p^2 (w_1, w_2)={w_1^2 \\sigma_1^2 + w_2^2 \\sigma_2^2}'}
                                    </Formula>
                                    mit der Nebenbedingung <Inline>{'w_1+w_2=1'}</Inline>. Wir ersetzen <Inline>{'w_2'}</Inline> mit <Inline>{'w_2=1-w_1'}</Inline>.
                                    Nun können wir in diesem Fall wie in der Schule gelernt einfach ableiten und nach Null umstellen.
                                    
                                    <Formula>
                                        {'\\frac{\\partial}{\\partial\\,w_1}\\left({w_1^2 \\sigma_1^2 + (1-w_1)^2 \\sigma_2^2}\\right)=2\\sigma_1^2 w_1 - 2 \\sigma_2^2 (1-w_1)'}
                                    </Formula>

                                    <Formula>
                                        {
                                            '\\begin{align}' +
                                            '2\\sigma_1^2 w_1 &- 2 \\sigma_2^2 (1-w_1) \\overset{!}{=} 0' + '\\\\' +
                                            '2\\sigma_1^2 w_1 &= 2 \\sigma_2^2 (1-w_1)' + '\\\\' +
                                            '2\\sigma_1^2 w_1 &= 2 \\sigma_2^2 - 2\\sigma_2^2 w_1' + '\\\\' +
                                            '\\sigma_1^2 w_1 &=  \\sigma_2^2 - \\sigma_2^2 w_1' + '\\\\' +
                                            '\\sigma_1^2 w_1 + \\sigma_2^2 w_1 &=  \\sigma_2^2' + '\\\\' +
                                            '(\\sigma_1^2 + \\sigma_2^2) w_1 &=  \\sigma_2^2' + '\\\\' +
                                            'w_1 &=  \\frac{\\sigma_2^2}{\\sigma_1^2 + \\sigma_2^2}' + '\\\\' +
                                            '\\end{align}'
                                            }
                                    </Formula>
                                    Konkret bedeutet das für unsere Assets
                                    <Formula>
                                        {
                                            '\\begin{align}' +
                                            'w_1 &=  \\frac{4^2}{3^2 + 4^2} = \\frac{16}{25}=0.64' + '\\\\' +
                                            'w_2 &= 1-w_1 = \\frac{3^2}{3^2 + 4^2} = \\frac{9}{25}=0.36' + '\\\\' +
                                            '\\end{align}'
                                            }
                                    </Formula>


                                    Das Rechenergebnis, also die Allokation, stimmt mit den Daten aus der Abbildung (64%, 36%) überein.
                                    Wir halten fest: Durch eine optimale Kombination von zwei unkorrelierten Wertpapieren kann man das Risiko nur minimieren.
                                    <Formula>{'\\sigma_{\\text{opt}}\\leq \\min(\\sigma_1, \\sigma_2)\\text{.}'}</Formula>

                                    Die Gewichte des optimalen Portfolios berechnet sich also aus dem Verhältnis der Varianzen der
                                    Renditen des jeweils anderen Wertpapiers
                                    <Formula>{'w_A = \\frac{\\operatorname{Var}(R_B)}{\\operatorname{Var}(R_A)+\\operatorname{Var}(R_B)}\\text{,}'}</Formula>
                                    <Formula>{'w_B = \\frac{\\operatorname{Var}(R_A)}{\\operatorname{Var}(R_A)+\\operatorname{Var}(R_B)}\\text{.}'}</Formula>
                                </div>
                                <div>
                                    <div className="font-bold text-xl my-3">
                                        Fall <Inline inline>{'\\rho_{12}=-1'}</Inline>
                                    </div>
                                    Wenn <Inline inline>{'\\rho_{12}=-1'}</Inline> gilt, sind die Assets negativ linear korreliert.
                                    Beispielsweise sind ein Wertpapier und ein Derivat, das dieses Wertpapier shortet stark negativ korreliert.
                                    Gold wird auch sehr gerne gekauft, da angenommen wird, dass der Goldpreis sich relativ gegenläufig zum Aktienmarkt in Krisenzeiten entwickelt.
                                    <div className="flex justify-center">
                                        <img src="https://tdbr.de/blog/out_1.png" alt="negativ korreliert"/>
                                    </div>
                                    Es gibt scheinbar eine Portfolioallokation, die ohne Schwankung Ertrag erzielt.
                                    Genau die Gewichtung, dessen Risiko die x-Achse berührt.
                                    In der Grafik sehen wir, dass sie bei ca. 57% Asset 1 und 43% Asset 2 liegt.
                                    Gibt es denn Wertpapiere, die sich beide genau gegensätzlich bewegen und beide Renditen positive sind? Wohl kaum!
                                    Denn sobald das eine Wertpapier eine positive Rendite erreicht, müsste das andere ja eine negative Rendite erzielen.
                                    Wenn es diesen Fall in der Realität geben würde, gäbe es eine Portfolioallokation, die immer mit 100% Sicherheit und ohne Risiko eine Rendite erwirtschaften,
                                    obwohl die einzelnen Wertpapiere beliebigem Risiko ausgesetzt sein könnten.
                                </div>
                                <div>
                                    <div className="font-bold text-xl my-3">
                                        Fall <Inline inline>{'\\rho_{12}=1'}</Inline>
                                    </div>
                                    Wenn <Inline inline>{'\\rho_{12}=1'}</Inline> gilt, sind die Assets positiv linear korreliert.

                                    In der Realität gibt es solche Wertpapiere. Zum Beispiel eine Aktie und ein Derivat, dass diese Aktie hebelt.

                                    <div className="flex justify-center">
                                        <img src="https://tdbr.de/blog/out_201.png" alt="positiv korreliert"/>
                                    </div>

                                    In diesem Fall erleichtert sich auch das Berechnen der Portfoliovolatilität, da <Inline inline>{'\\rho=1'}</Inline>.
                                    <Formula>
                                        {'\\sigma_p = \\sqrt{w_1^2 \\sigma_1^2 + w_2^2 \\sigma_2^2 + 2w_1w_2\\rho_{12}\\sigma_1\\sigma_2} = \\sqrt{w_1^2 \\sigma_1^2 + w_2^2 \\sigma_2^2 + 2w_1w_2\\sigma_1\\sigma_2}'}
                                    </Formula>
                                    Binomische Formel angewandt!
                                    <Formula>
                                        {'\\sigma_p = \\sqrt{(w_1 \\sigma_1)^2 + (w_2 \\sigma_2)^2 + 2(w_1\\sigma_1)(w_2\\sigma_2)} = \\sqrt{((w_1 \\sigma_1) + (w_2 \\sigma_2))^2} = {w_1 \\sigma_1 + w_2 \\sigma_2}'}
                                    </Formula>
                                    Was sagt uns das Ergebnis?
                                    Bei perfekter positiver Korrelation bringen die beiden Assets genau ihre Volatilität nach ihrer jeweiligen Gewichtung mit.
                                    Wir können also aus zwei Assets ein drittes basteln, das eine geplante Volatilität besitzt.
                                    Zum Beispiel: Wir haben die Möglichkeit Aktien zu kaufen und Derivate, die diese Aktie 10-fach hebelt.
                                    Wir können nun eine Portfolio bauen, das sich quasi wie ein beliebieger anderer Hebel zwischen 1 und 10 verhält.
                                </div>
                            </div>
                        </div>
                        <Sep/>
                        <div className="flex justify-center">
                            <ReactPlayer url='https://tdbr.de/blog/out_new.mp4' playing loop muted/>
                        </div>
                        Schauen wir uns nun die Spur der optimalen Portfolien an.
                        <div className="flex justify-center">
                            <ReactPlayer url='https://tdbr.de/blog/out_with_trace.mp4' playing loop muted/>
                        </div>

                        Je kleiner der Korrelationskoeffizient der Aktien ist, desto schneller sinkt das Risiko.
                        

                        <div>
                            Spannend wird es, wenn wir uns Portfolien bestehend aus mehreren Aktien anschauen.
                            Im mehrdimensionalem Fall nutzen wir die Kovarianzmatrix, die die Varianzen und Kovarianzen aller Werpapierkombinationen beinhaltet.
                            <Formula>
                            {'\\Sigma^2:=\\begin{pmatrix}\\sigma _{1}^{2}&\\sigma _{12}&\\cdots &\\sigma _{1n}\\\\\\\\\\sigma _{21}&\\sigma _{2}^{2}&\\cdots &\\sigma _{2n}\\\\\\\\\\vdots &\\vdots &\\ddots &\\vdots \\\\\\\\\\sigma _{n1}&\\sigma _{n2}&\\cdots &\\sigma _{n}^{2}\\end{pmatrix}'}
                            </Formula>
                            Die Portfoliovolatilität berechnet sich dann als Quadratform
                            <Formula>
                                {'\\sigma_p=\\sqrt{\\sum_{i=1}^N\\sum_{j=1}^Nw_i\\rho_{ij}w_j}=\\sqrt{w^T\\Sigma^2w}'}
                            </Formula>

                            Das Minimierungsproblem lautet dann
                            <Formula>
                                {'\\min_{w} {w^T\\Sigma^2w}'}
                            </Formula>
                        </div>
                        Schauen wir uns nochmal zufällige Portfolien bestehend aus 3 Assets an.
                        <div className="flex justify-center w-full">
                            <div className="w-3/5">
                                <ImageGallery items={gallery} autoPlay/>
                            </div>
                        </div>
                        Toll! Unter den Bildern gibt es Portfolien, die ohne Risiko eine sichere Rendite erwirtschaften.
                        Gibt es diese Portfolien auch für beliebige Anzahl von Wertpapieren? Sind solche Portfolien überhaupt möglich?
                        Kann <Inline inline>{'\\rho_{12}=-1,\\rho_{23}=-1,\\rho_{13}=-1'}</Inline> überhaupt auftreten?
                        Oder kann <Inline inline>{'\\rho_{12}=1,\\rho_{23}=-1,\\rho_{13}=1'}</Inline> überhaupt auftreten?

                        Angenommen Asset 1 macht eine positive Rendite.
                        Dann muss wegen <Inline inline>{'\\rho_{12}=-1'}</Inline> Asset 2 eine negative Rendite machen.
                        Wenn Asset 2 eine negative Rendite erzielt müsste wegen <Inline inline>{'\\rho_{23}=-1'}</Inline> Asset 3 eine positive Rendite machen.
                        Wenn Asset 3 eine positive Rendite erzielt müsste wegen <Inline inline>{'\\rho_{13}=-1'}</Inline> Asset 1 eine negative Rendite erzielen.
                        Das steht jedoch im Widerspruch zu unserer Annahme. Das heißt dieser Fall kann gar nicht auftreten.

                    </BlogEntry>
                    <div className="h-24"></div>
                    <BlogEntry id="risikopraemie" title="Was sind Risikoprämien? Und wie definiert man Risikoaversion oder -affinität?" subtitle="25.08.2019 - Tim David Brachwitz">
                    Stell dir vor ich biete dir drei Optionen an.
                    Ich schenke dir ein Los, das mit einer Wahrscheinlichkeit von 50% 49€ und mit 50% 1€ gewinnt.
                    Oder aber ein Los, das mit Wahrscheinlichkeit von 50% einen Gewinn von 30€ und mit 50% 20€ bringt.
                    Beide Lose haben den selben Erwartungswert und zwar 25€.
                    Als letztes steht dir die dritte Option zuer Verfügung: 25€ bar auf die Kralle.
                    <br/>
                    Für welche Option entscheidest du dich?
                    Welche Option ist wie risikoreich?
                    Wie messen wir das Risiko?
                    Können wir Spieler (Investoren) in verschiedene Risikoklassen einordnen?
                    Können wir Optionen bzw. Investitionen ein Risikomaß zuordnen?
                    
                    <div className="flex justify-center my-3">
                        <img src="https://tdbr.de/blog/optionen.png" alt="Optionen"/>
                    </div>
                    
                    Viele erfahrene Investoren würden in diesem Fall nach Erwartungswert und Varianz entscheiden.
                    Option 1 hat die größte Streuung, Option 2 eine kleinere und Option 3 gar keine.
                    Leider schießt die reine mathematische Bewertung in diesem Fall oft am eigentlichen Sinn vorbei.
                    <br/><br/>
                    Angenommen Ihr Chef zahlt diesen Monat Ihr Gehalt nicht einfach so aus, sondern wirft eine Münze.<br/>
                    <ul>
                        <li className="ml-3">Bei Kopf bekommst du das doppelte Ihres Gehaltes plus einen Bonus von 100€.</li>
                        <li className="ml-3">Bei Zahl gehst du diesen Monat leer aus.</li>
                    </ul>
                    Hier ist das erwartete Gehalt durch den Bonus von 100€ größer als dein einfaches Gehalt.
                    Menschen, die auf das Gehalt kurzfristig nicht angewisen sind, fänden die risikoreichere Gehaltszahlung möglicherwiese gut.
                    Trotzdem gibt es Menschen, die keine Ersparnisse haben und das Geld benötigen, um ihre Miete zu bezahlen.
                    Das Ziel ist nicht einfach den Erwartungswert maximieren sondern zu quantifizieren wie schlimm das Verlustszenario gegenüber dem Gewinnszenario ist.
                    <br/><br/>
                    Für diese Art der Entscheidungen behilft man sich im Risikomanagement mit dem Konzept von Nutzenfunktionen. 
                    Jedes Gut, das du besitzt stellt dir Nutzen in einem gewissen Maß zur Verfügung.
                    Dein Auto nützt dir, deine Lebensmittel bringen dir einen Nutzen und Geld nützt dir.
                    Was man jedoch beobachten kann ist, dass eine Häufung eines Guts mit Erhöhung der Menge an Nutzen verliert.
                    Das heißt: Dein erstes Auto schafft großen Nutzen, da du jederzeit damit herumfahren kannst.
                    Dein zweites Auto bringt dir auch Nutzen jedoch weniger als das erste Auto, denn du können nicht gleichzeitig mit beiden Autos fahren.
                    Je mehr Autos du besitzt, desto nutzloser wird jedes weitere.
                    Den Verlust an Nutzen beschreibt man durch das Konzept des abnehmenden Grenznutzens oder auch Gossensche Gesetz.
                    <br/><br/>
                    Um dies zu modellieren führen wir Nutzenfunktionen ein.
                    Nutzenfunktionen quantifizieren den Nutzen eines Guts.
                    Unser Ziel beim Investieren wird es nicht den puren Ertrag zu maximieren sondern den Nutzen, den der Ertrag bringt.
                    <br/>
                    Die einfachste Nutzenfunktion dieser Art ist die Wurzelfunktion.
                    Dann bringt das erste Auto einen Nutzen von 1.
                    Das zweite Auto nur noch einen Nutzen von <Inline inline>{'\\sqrt{2}\\approx1.41'}</Inline>.
                    Das dritte <Inline inline>{'\\sqrt{3}\\approx1.73'}</Inline>. Erst das vierte Auto bringt einen Nutzen von <Inline inline>{'\\sqrt{4}=2'}</Inline>.
                    <div className="flex justify-center my-3">
                        <table>
                            <thead>
                                <tr>
                                    <th className="px-2 py-1 border-b">Anzahl der Autos</th>
                                    <th className="px-2 py-1 border-b">Nutzen</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="px-2 py-1">1</td>
                                    <td className="px-2 py-1"><Inline inline>{'\\sqrt{1}=1'}</Inline></td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-1">2</td>
                                    <td className="px-2 py-1"><Inline inline>{'\\sqrt{2}\\approx1.4142'}</Inline></td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-1">3</td>
                                    <td className="px-2 py-1"><Inline inline>{'\\sqrt{3}\\approx1.73'}</Inline></td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-1">4</td>
                                    <td className="px-2 py-1"><Inline inline>{'\\sqrt{4}=2'}</Inline></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    Das gleiche Konzept findet auch bei Geld Anwendung.
                    Stell dir den Nutzen deines Gehalts vor.
                    Die ersten Euros, die du verdienst, sind sehr wichtig, denn damit muss man seinen Lebensunterhalt bestreiten.
                    Mit mehr Geld kann ich mir natürlich mehr Dinge kaufen und habe auch einen höheren Nutzen jedoch irgendwann spielt es keine große Rolle mehr, ob ich 50.000€ oder 51.000€ pro Monat verdiene.
                    Der gefühlte Unterschied zwischen 50.000€ zu 51.000€ ist viel geringer als zwischen 0€ (arbeitslos) und 1000€.
                    <br/><br/>
                    Kommen wir zurück zu unserem Beispiel.
                    Wir haben zwei Möglichkeitem, die unsichere Ausgänge haben.
                    Eine mit potenziellen Gewinnen von 1€ oder 49€.
                    Jetzt wenden wir darauf unsere Nutzenfunktion an.
                    Wir messen wieviel Nutzen uns der erste Euro bringt und wieviel der 49. Euro.

                    <div className="flex justify-center my-3">
                        <table>
                            <thead>
                                <tr>
                                    <th className="px-2 py-1 border-b">Ausgang des Investments</th>
                                    <th className="px-2 py-1 border-b">Nutzen des Ertrages</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="px-2 py-1">1</td>
                                    <td className="px-2 py-1">1</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-1">49</td>
                                    <td className="px-2 py-1">7</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    

                    Diese beiden Möglichkeiten tragen wir in einem Diagramm ab.
                    In Blau haben wir die Wurzelfunktion gezeichnet, die unsere Nutzenfunktion darstellt.

                    <div className="flex justify-center my-3 w-full">
                        <img src="https://tdbr.de/blog/2.png" className="w-3/5" alt="Nutzenfunktion"/>
                    </div>

                    Wir können auch nun den Erwartungswert eintragen, den wir bereits berechnet haben.
                    Wir setzen an der x-Achse am Punkt 25 an und ziehen eine Linie bis nach oben bis wir die Nutzenfunktion treffen.
                    Und tragen den Funktionswert nach links ab.
                    Wurzel aus 25!? Natürlich 5.
                    Das heißt im Erwartungswert bringt uns diese Option einen Nutzen von 5.
                    <div className="flex justify-center my-3 w-full">
                        <img src="https://tdbr.de/blog/3.png" className="w-3/5" alt="Nutzenfunktion"/>
                    </div>
                    Wir können es aber auch andersherum machen.
                    Wir tragen nicht den Erwartungswert des Ertrages direkt ab sondern berechnen den Erwartungswert des Nutzens.
                    Das heißt wir können das gleiche Spiel auf der y-Achse machen.
                    Die möglichen Nutzen, die wir erreichen können sind: 1 und 7.
                    Der Mittelwert dieser beiden ist <Inline inline>{'\\frac{1+7}{2} = 4'}</Inline>.
                    Jetzt können wir auf der y-Achse die 4 abtragen und den passenden x-Wert dazu finden.
                    Das wäre in diesem Fall <Inline inline>{'4 = \\sqrt{x} \\Rightarrow x = 16'}</Inline>.
                    <div className="flex justify-center my-3 w-full">
                        <img src="https://tdbr.de/blog/4.png" className="w-3/5" alt="Nutzenfunktion"/>
                    </div>
                    Diesen x-Wert  nennt man Sicherheitsäquivalent (certainty equivalent).
                    Die Differenz zwischen Erwartungswert und Sicherheitsäquivalent nennt man Risikoprämie.
                    Die Risikoprämie ist der Betrag, den mir jemand jetzt und sofort in bar zahlen müsste, damit ich die risikoreiche Investition genauso bewerte wie eine Investition, bei der ich sicher zu 100% 25€ erhalte.
                    Das ist in unserem Beispiel <Inline inline>{'25–16=9'}</Inline>.
                    Das heißt mir müsste jemand mehr als 9€ in die Hand drücken damit ich die risikoreiche Investition bevorzuge.
                    <div className="flex justify-center my-3 w-full">
                        <img src="https://tdbr.de/blog/5.png" className="w-3/5" alt="Nutzenfunktion"/>
                    </div>

                    Und genau durch die Wahl der Nutzenfunktion definiert man die Risikoeinstellung.
                    Ein Investor, der die Risikonutzenfunktion <Inline inline>{'f(x)=x'}</Inline> verwendet, erhält immer ein Sicherheitsäquivalent, das gleich dem Erwartungswert der Investition ist.
                    Daher verlangt er eine Risikoprämie von 0 Euro.
                    Ist die Risikonutzenfunktion konvex handelt es sich um einen risikoaffinen Investor, der immer die Investition wählt, die risikoreicher ist.
                    In der Realität haben die meisten eine Risikoaversion.
                    Das sind alle konkaven funktionen wie die Wurzelfunktion oder Logarithmus.
                    
                    Zusammengefasst: Du kannst deine eigene Risikoneigung als Nutzenfunktion modellieren.
                    Sobald du dies getan hast, kannst du Investitionen nach der Risikoprämie ordnen.
                    Zurück zum Eingangsbeispiel. Die Entscheidung welche Option du wählst, hängt von deiner Nutzenfunktion ab.

                    Die Nutzenfunktion ist gegeben durch
                    <Formula>
                        {'U(x)=\\sqrt{x}'}
                    </Formula>
                    Das Sicherheitsäquivalent berechnet sich aus
                    <Formula>
                        {'S={U^{-1}}(\\operatorname{E}(U(X)))'}
                    </Formula>
                    Die Risikoprämie ist
                    <Formula>
                        {'R_P = \\operatorname{E}(X) -S'}
                    </Formula>

                    Berechnen wir die konkrete Werten für unsere Möglichkeiten.

                    Dazu brauchen wir noch eine kleine Regel wie man Erwartungswerte von transformierten Zufallsvariablen berechnet.
                    Diese Regel lautet
                    <Formula>
                        {'\\operatorname{E}(h(X))=\\sum_i h(x_i) p_i'}
                    </Formula>

                    Wir kennen die Umkehrfunktion der Wurzelfunktion
                    <Formula>
                        {'U^{-1}(x)=x^2'}
                    </Formula>
                    
                    Dann sind die konkreten Werte der Optionen

                    <div className="flex justify-center my-3">
                    <table className="table-auto">
                        <tbody>
                        <tr>
                            <th></th>
                            <th className="px-2 py-1 border-b">
                                <Formula>
                                    {'\\operatorname{E}(U(X))'}
                                </Formula></th>
                            <th className="px-2 py-1 border-b">
                                <Formula>
                                    {'S={U^{-1}}(\\operatorname{E}(U(X)))'}
                                </Formula>
                            </th>
                            <th className="px-2 py-1 border-b">
                                <Formula>
                                    {'R_P'}
                                </Formula>
                            </th>
                        </tr>
                        <tr>
                            <td className="px-5 py-4">Option 1</td>
                            <td className="px-5 py-4">
                                <Formula>
                                    {'\\sqrt{1} \\frac{1}{2} + \\sqrt{49} \\frac{1}{2} = \\frac{1}{2} + \\frac{7}{2} = 4'}
                                </Formula>
                            </td>
                            <td className="px-5 py-4">
                                <Formula>
                                    {'4^2=16'}
                                </Formula>
                            </td>
                            <td className="px-5 py-4">
                                <Formula>
                                    {'25-16=9'}
                                </Formula>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-5 py-4">Option 2</td>
                            <td className="px-5 py-4">
                                <Formula>
                                    {'\\sqrt{20} \\frac{1}{2} + \\sqrt{30} \\frac{1}{2}\\approx 4.97'}
                                </Formula>
                            </td>
                            <td className="px-5 py-4">
                                <Formula>
                                    {'\\approx 24.75'}
                                </Formula>
                            </td>
                            <td className="px-5 py-4">
                                <Formula>
                                    {'25-24.75=0.25'}
                                </Formula>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-5 py-4">Option 3</td>
                            <td className="px-5 py-4">
                                <Formula>
                                    {'\\sqrt{25}=5'}
                                </Formula>
                            </td>
                            <td className="px-5 py-4">
                                <Formula>
                                    {'5^2 =25'}
                                </Formula>
                            </td>
                            <td className="px-5 py-4">
                                <Formula>
                                    {'25-25=0'}
                                </Formula>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    Jetzt haben wir ein Risikomaß nach dem wir die Investitionen ordnen können.
                    Wir sehen, dass die Risikoprämie bei Option 2 sehr klein ist.
                    Das liegt daran, dass die beiden Ausgangsszenarien nicht sehr stark vom Erwartungswert abweichen,
                    was heißt, dass das Worst Case Szenario (20€) gar nicht so schlimm ist.


                    Mit unserer Nutzenfunktion können wir nun auch eine 4. Option basteln, die nach unserem Maß äquivalent zur sicheren Alternative ist.

                    Durch dieses Maß lassen sich also alle Investitionen vergleichen.
                    

                    Mit diesem Ansatz kann man viele verschiedene Risikoeinstellungen modellieren.
                    {/**
                     * 1. Verschiedene Risikonutzenfkts modellieren mit parametern
                     * Dann
                        Die Schwierigkeit besteht darin.


                        Man könnte den Nutzer Fragen stellen wie.

                        Würden Sie lieber ein 0% Rendite erhalten oder mit 50% Wkt -1% und mit 50% Wkt +1%
                        Würden Sie lieber ein 0% Rendite erhalten oder mit 50% Wkt -2% und mit 50% Wkt +2%
                        Würden Sie lieber ein 0% Rendite erhalten oder mit 50% Wkt -3% und mit 50% Wkt +3%
                        Würden Sie lieber ein 0% Rendite erhalten oder mit 50% Wkt -4% und mit 50% Wkt +4%
                        
                        Dann 
  

                     */}


                    




                    </BlogEntry>
                    <div className="h-24"></div>
                    <BlogEntry id="animation" title="Wie ich meine Animationen mache - Ein simples Beispiel." subtitle="25.09.2019 - Tim David Brachwitz">
                        Ich schreibe meine Animationen fast ausschließlich mit matplotlib und ffmpeg in jupyter lab.
                        matplotlib generiert die einzelnen Frames und mit ffmpeg render ich die Videos.
                        matplotlib hat zwar eigene Tools für Animationen <span className="font-mono bg-blue-100 rounded px-1">matplotlob.animation</span>,
                        ich bevorzuge aber das Video aus den einzelnen Frames selbst zu rendern, da man mehr Einstellungen vornehmen kann.
                        Ich nutze den 'Agg'-Modus, um zu vermeiden, dass mir die Plots in jupyter angezeigt werden.
                    <div className="my-3">
                    <CodeBlock lang="python">
{`import numpy as np
import matplotlib
matplotlib.use('Agg')
import matplotlib.pyplot as plt`}
                    </CodeBlock>
                    </div>
                    Dann definiere ich alle Variablen, die sich während der Frames nicht ändern.
                    Ich überlege mir vorher wie lang das Video sein soll,
                    reche mir daraus die benötigten Frames aus und zerlege das Intervall,
                    in dem die Animation spielt per <span className="font-mono bg-blue-100 rounded px-1">np.linspace(a, b, frames)</span>.
                    So läuft die Animation immer vollständig durch egal wieviele Frames das Video hat. bzw. wie langsam es sein soll.
                    <div className="my-3">
                    <CodeBlock lang="python">
{`frames = 30 * 8 # Bei 30 fps soll das Video 8s lang sein

f = np.sin
a = 0
b = 4 * np.pi

x = np.linspace(a, b, frames, endpoint=True)
y = f(x)
y_max = y.max() + (y.max() - y.min()) / 4
y_min = y.min() - (y.max() - y.min()) / 4`}
                    </CodeBlock>
                    </div>
                    Ich gehe nun durch alle Frames durch, erzeuge sie einzeln und speicher sie ab. (Achte darauf, dass der Ordner sin existiert)
                    Mit <span className="font-mono bg-blue-100 rounded px-1">plt.figure(figsize=(8, 4.5))</span> gebe ich das Verhältnis an und mit <span className="font-mono bg-blue-100 rounded px-1">plt.savefig('sin/out_' + str(k) + '.png', dpi=100)</span> setze ich über die DPI die Qualität des Bildes.
                    <div className="my-3">
                    <CodeBlock lang="python">
{`for k in range(frames):
    plt.figure(figsize=(8, 4.5))
    plt.ylim((y_min, y_max))
    plt.xlim((a, b))

    plt.plot(x[:k], y[:k])

    plt.gca().spines['right'].set_visible(False)
    plt.gca().spines['top'].set_visible(False)

    plt.savefig('sin/out_' + str(k) + '.png', dpi=100)
    plt.close('all')`}
                    </CodeBlock>
                    </div>
                    Mit <span className="font-mono bg-blue-100 rounded px-1">%%system</span> kann ich sofort Befehle der Kommandozeile verwenden.
                    Um aus den einzelnen Frames ein Video zu rendern nutze ich ffmpeg.
                    Ich gebe an, dass das Video 30 fps haben soll und das Format.
                    Die Videos sind in diesem Format sehr klein und sind qualitativ vollkommen ausreichend für das Web.
                    <div className="my-3">
                    <CodeBlock lang="python">
{`%%system
ffmpeg -loglevel warning -y -i sin/out_%d.png -c:v libx264 -vf fps=30 -pix_fmt yuv420p sin.mp4`}
                    </CodeBlock>
                    </div>
                    Damit du das Video nicht jedesmal per Hand öffnen musst, kannst du das Video per HTML in jupyter einblenden lassen.
                    <div className="my-3">
                    <CodeBlock lang="python">
{`%%HTML
<video autoplay loop>
      <source src="sin.mp4" type="video/mp4">
</video>`}
                    </CodeBlock>
                    </div>
                    <div className="flex justify-center">
                        <ReactPlayer url='https://tdbr.de/blog/sin.mp4' playing loop muted/>
                    </div>
                    <div className="flex justify-center">
                        <ReactPlayer url='https://tdbr.de/blog/transform.mp4' playing loop muted/>
                    </div>
                    <div className="flex justify-center">
                        <ReactPlayer url='https://tdbr.de/blog/transform2.mp4' playing loop muted/>
                    </div>
                    <div className="flex justify-center">
                        <ReactPlayer url='https://tdbr.de/blog/transform3.mp4' playing loop muted/>
                    </div>
                    <div className="flex justify-center">
                        <ReactPlayer url='https://tdbr.de/blog/3dplot.mp4' playing loop muted/>
                    </div>

                    Viel Spaß beim Nachbauen!

                    </BlogEntry>
                    <div className="h-24"></div>
                    <BlogEntry id="erwartungswert-varianz" title="Erwartungswert? Varianz? Kovarianz? Korrelation? - Moment mal! Was ist das?" subtitle="04.10.2019 - Tim David Brachwitz">
                        Ich nutze in meinen Blogartikeln oft die Begriffe Erwartungswert und Varianz?
                        Aber was ist sind diese Kennzahlen überhaupt?
                        In diesem Artikel möchte ich die Begriffe <span className="font-bold">arithmetisches Mittel</span>, <span className="font-bold">Erwartungswert</span>, <span className="font-bold">Varianz</span>, <span className="font-bold">Standardabweichung</span>, <span className="font-bold">Kovarianz</span> und <span className="font-bold">Korrelation</span> klären.
                        <br/>

                        Um diese Begriffe zu klären, möchte ich nochmal kurz versuchen einen Unterscheid zwischen Statistik und Wahrscheinlichkeitstheorie definieren.

                        Stell dir vor du hast eine Münze vor dir und bist kurz davor diese zu werfen.
                        Sofern das Zufallsexperiment noch nicht passiert ist und wir dessen Ausgang nicht kennen, ist die Wahrscheinlichkeitstheorie für alle Fragen verantwortlich.
                        Sobald du aber konkrete Ergebnisse aus dem Zufallsexperiment hast, betreibts du quasi Statistik.

                        Das heißt Wahrscheinlichkeitstheorie wird vor dem eigentlichen Zufallsereignis betrieben und Statistik danach.

                        Eine Frage aus der Wahrscheinlichkeitstheorie: Wie wahrscheinlich ist es 10 mal hintereinander eine 6 mit einem Würfel zu werfen?
                        Eine Frage aus der Statistik: Wenn ich 10 mal hintereinander eine 6 mit einem Würfel geworfen habe, wie wahrscheinlich ist es, dass mein Würfel gezinkt ist. 
                        
                        Natürlich sind diese zwei Gebiete sehr eng miteinerander verwandt und verflochten und verschwimmen an vielen Stellen.
                        Beide Teilgebiete nutzen Konzepte aus beiden Welten und sind untrennbar.
                        <Sep/>

                        Der arithmetische Mittelwert einer Datenreihe ist vielen bereits bekannt. Es ist definiert als
                        <Formula>
                            {'\\bar{x} = \\frac{1}{n} (x_1 + x_2 + \\ldots + x_n)=\\frac1n \\sum_{i=1}^n{x_i}\\text{.}'}
                        </Formula>
                        Kurzgesagt: Wir addieren alle Werte und teilen sie durch die Anzahl.
                        <br/>
                        Angenommen ich hab 5 Würfelwürfe durchgeführt. 4 1 1 2 3.
                        Das arithmetische Mittel ist 
                        <Formula>{'\\bar{x} = \\frac{4+1+1+2+3}{5} = \\frac{11}{5} = 5.5\\text{.}'}</Formula>

                        Das Pendant aus der Wahrscheinlichkeitstheorie zum arithmetischen Mittel ist der Erwartungswert.
                        Der Erwartungswert gibt uns eine Kennzahl darüber wo sich der Schwerpunkt des Zufalls befindet.
                        Er wird in der Wahrscheinlichkeitstheorie mit <Inline>{'\\operatorname{E}(X)'}</Inline> bezeichnet.
                        In bestimmten Fällen wird der griechische Buchstabe <Inline>{'\\mu'}</Inline> verwendet.
                        <br/>
                        Nun müssen wir noch klären was mit <Inline>{'X'}</Inline> gemeint ist?
                        <Inline>{'X'}</Inline> ist eine Zufallsvariable und du kannst sie dir auch erstmals als solches vorstellen: Eine zufällige Variable.
                        Im Beispiel eines Würfels ist <Inline>{'X'}</Inline> ein Wert aus <Inline>{'\\{1,2,3,4,5,6\\}=I'}</Inline>.
                        Und wir Mathematiker setzen den Wert nicht fest. Wir lassen <Inline>{'X'}</Inline> offen.
                        Denn sobald wir <Inline>{'X'}</Inline> zu 4 oder 1 werden lassen, ist das Zufallsereignis geschehen und wir betreiben Statistik.
                        
                        Definiert ist der Erwartungswert wie folgt
                        <Formula>
                            {'\\operatorname{E}(X)=\\sum_{i\\in I}x_i \\cdot p_i'}
                        </Formula>
                        Dabei ist <Inline>{'p_i'}</Inline> die Wahrscheinlichkeit, dass <Inline>{'x_i'}</Inline> auftritt.
                        Die möglichen Ergebnisse werden mit den Wahrscheinlichkeiten gewichtet.
                        <br/>
                        Rechnen wir mal den Erwartungswert eines Würfelwurfes aus. Bei uns sind die <Inline>{'x_i'}</Inline> 1, 2, 3, 4, 5 und 6.
                        Die Wahrscheinlichkeit für all diese Ergebnisse sind jeweils <Inline>{'\\frac16'}</Inline>.
                        <Formula>
                            {'\\operatorname{E}(X)=\\sum_{i\\in I}x_i \\cdot p_i= \\frac16\\cdot1+\\frac16\\cdot2+\\frac16\\cdot3+\\frac16\\cdot4+\\frac16\\cdot5+\\frac16\\cdot6'}
                        </Formula>
                        <Formula>
                            {'= \\frac{1+2+3+4+5+6}{6}=3.5'}
                        </Formula>
                        Man könnte auch sagen: Im Schnitt wirfst du mit deinem Würfel eine 3.5.


                        <br/><br/>

                        Ich hab schon viele sagen hören: "Der Erwartungswert ist das, was man erwartet".
                        Ich halte diese Beschreibung für ungeeignet, denn in diesem Beispiel sieht man schon, dass 3.5 der Erwartungswert ist.
                        Ein Würfel hingegen hat gar nicht die Zahl 3.5 aufgedruckt.
                        Viel eher ist der Erwartungswert der Schwerpunkt der "Verteilung" von <Inline>{'X'}</Inline>.
                        In <Inline>{'X'}</Inline> steckt nämlich nicht nur, welche Werte rauskommen können (Würfel: 1,2,3,4,5,6),
                        sondern auch wie wahrscheinlich diese Werte sind, also wie die Wahrscheinlichkeiten auf die Werte "verteilt sind" (Würfel: Alle Ergebnisse haben Wahrscheinlichkeit <Inline>{'\\frac16'}</Inline>).
                        Die Verteilung von <Inline>{'X'}</Inline> beschreibt wie der Zufall, also die Wahrscheinlichkeit, auf die Werte verteilt ist.
                        Man nimmt also 100% Wahrscheinlichkeit und teilt diese auf die sechs Werte auf.
                        Bei einem Würfel sind es für jede Ausgangsmöglichkeit <Inline>{'\\frac16 \\approx 0.16666'}</Inline> also knapp 16.7% pro Würfelseite.
                        Natürlich könnten wir jetzt unseren Würfel zinken, so, dass er mit 50% eine 6 würfelt und die anderen Werte mit jeweils 10%.
                        Dann wäre dieser gezinkte Würfel anders verteilt.
                        Nennen wir die Zufallsvariable dieses Würfels mal <Inline>{'Y'}</Inline>.
                        Dann gilt
                        <Formula>
                            {'\\operatorname{E}(Y) = 0.5\\cdot6 + 0.1\\cdot1 + 0.1\\cdot2 + 0.1\\cdot3 + 0.1\\cdot4 + 0.1\\cdot5 = \\frac12\\cdot6 + \\frac{1+2+3+4+5}{10}=3+1.5=4.5'}
                        </Formula>
                        Da <Formula>
                            {'3.5=\\operatorname{E}(X)<\\operatorname{E}(Y)=4.5'}
                        </Formula> wissen wir auch mathematisch, dass der gezinkte Würfel der bessere Würfel fürs Mensch-Ärger-Dich-Nicht spielen ist.
                        Wir wissen auch "wieviel" besser er ist und zwar im Schnitt (im Erwartungswert) eine ganze Augenzahl pro Würfelwurf!

                        Der Erwartungswert ist also eine Prognose für den durchschnittlichen Ausgang bei Wiederholung eines Zufallsexperiments.
                        Der Erwartungswert ist eine Prognose für das arithmetische Mittel der Realisierungen der Zufallsvariable.

                        <Sep/>
                        Die Varianz ist ein Maß für die Streuung von Datenpunkten.
                        In beiden Fachgebieten heißt die Kennzahl Varianz.
                        Jedoch wird sie in der Wahrscheinlichkeitstheorie mit <Inline>{'\\operatorname{Var}(X)'}</Inline> und in bestimmten Fällen mit <Inline>{'\\sigma^2'}</Inline> und in der (deskriptiven) Statistik mit <Inline>{'s^2'}</Inline> abgekürzt.
                        Außerdem warum hat die Varianz immer ein Quadrat im Exponenten? Was hat das mit Quadraten zutun?
                        
                        Werte die nahe beieinander liegen sollten eine kleine Varianz haben.
                        Und Werte die stark voneinander entfernt sind, sollten eine große Varianz haben.

                        Bei der Varianz geht es um Abweichungen der Werte zum Mittelwert.
                        Ich hab mal eine Zufallsvariable programmiert und zufällig Werte auf der Achse gewürfelt.
                        Beide Zufallsvariablen sind nahezu identisch. Sie unterscheiden sich bloß in der Varianz.
                        In Rot ist der Mittelwert 
                        <div className="flex justify-center my-3 w-full">
                        <img src="https://tdbr.de/blog/A.png" className="w-3/5" alt="Datenreihe 1"/>
                        </div>
                        <div className="flex justify-center my-3 w-full">
                        <img src="https://tdbr.de/blog/B.png" className="w-3/5" alt="Datenreihe 2"/>
                        </div>



                        <Sep/>
                        Die Standardabweichung
                    {
                        // Was ist Varianz. Warum gibt es immer zwei Kennzahlen Std und Var, Beispiel mit €?
                    }
                        <div className="flex justify-center">
                            <ReactPlayer url='https://tdbr.de/blog/var.mp4' playing loop muted/>
                        </div>

                    </BlogEntry>
                    <BlogEntry id="latex-tipps" title="Eine kleine Sammlung von LaTeX Tipps" subtitle="27.10.2019 - Tim David Brachwitz">
                    {
                        /*
                        Die wichtigsten LaTeX Tipps.
                        . Nach Equation
                        \left. und \right. als Platzhalterklammer
                        \catcode
                        Unbreaking Leerzeichen
                        Niemals \\ und \newline nutzen
                        pt vermeiden
                        Mit Abkürzung Satz beenden.
                        Nutze glqq aus german package
                        Nutze - und -- und --- richtig
                        space unterschiede \, in dx
                        mit math rm
                        Vermeide hor linien in tabellen
                        */
                    }
                    <ul>
                        <li>
                            Nutze das non-breaking space!
                            
                            Falls du zwei Wörter hast, die direkt zusammengelesen werden sollten und ein Umbruch den Lesefluss massiv behindern kann, ziehe das non-breaking space in Erwägung.
                            <div className="my-3"><CodeBlock lang="tex">
                                {`Distributed~Ledger`}
                            </CodeBlock></div>
                            Das gilt vorallem auch bei Zahlen, die nach einem Wort kommen.
                            <div className="my-3"><CodeBlock lang="tex">
                                {`Oktober~2019`}
                            </CodeBlock></div>
                            und vor auch bei allen Referenzen, die du machst.
                            <div className="my-3"><CodeBlock lang="tex">
                                {`Abbildung~\\ref{myimage}, Kapitel~\\ref{mychapter}, Variante~B, `}
                            </CodeBlock></div>
                        </li>
                        <li>
                            
                        </li>
                    </ul>

                    </BlogEntry>
                    */}
                    <BlogEntry id="leverage-effekt" title="Der Leverage-Effekt und das Leverage-Risiko" subtitle="03.03.2020 - Tim David Brachwitz">

                        Das Gesamtkapital ist die Summe von Fremd- und Eigenkapital <MathJax.Node formula={'\\text{GK}=\\text{EK}+\\text{FK}'} inline/>.
                        Der Gewinn, der das Gesamtkapital erbringt ist <MathJax.Node formula={'r_G\\cdot\\text{GK}'} inline/>.
                        Analog bringt der Einsatz des Eigenkapitals allein einen Gewinn von
                        <MathJax.Node formula={'r_E\\cdot\\text{EK}'} inline/>.
                        Die Fremdkapitalkosten belaufen sich auf <MathJax.Node formula={'r_F\\cdot\\text{FK}'} inline/>.

                        Also gilt
                        <MathJax.Node formula={'\\underbrace{r_E\\cdot\\text{EK}}_{\\text{Nettogewinn}}=\\underbrace{r_G\\cdot\\text{GK}}_{\\text{Bruttogewinn}}-\\underbrace{r_F\\cdot\\text{FK}}_{\\text{FK-Kosten}}\\text{.}'}/>
                        Umformen nach Eigenkapitalrendite ergibt

                        <Formula>
                            {
                                '\\begin{align}' +
                                'r_E&=\\frac{r_G(\\text{EK}+\\text{FK})-r_F\\text{FK}}{\\text{EK}}' + '\\\\' +
                                'r_E&=r_G(1+\\frac{\\text{FK}}{\\text{EK}})-r_F\\frac{\\text{FK}}{\\text{EK}}' + '\\\\' +
                                '\\end{align}'
                                }
                        </Formula>

                        Wir definieren nun den Verschuldungsgrad als <MathJax.Node formula={'\\text{VG}:=\\frac{\\text{FK}}{\\text{EK}}'} inline/>.

                        <Formula>
                            {
                                '\\begin{align}' +
                                'r_E&=r_G(1+\\text{VG})-r_F\\text{VG}' + '\\\\' +
                                'r_E&=r_G+r_G\\text{VG}-r_F\\text{VG}' + '\\\\' +
                                'r_E&=r_G+(r_G-r_F)\\text{VG}' +
                                '\\end{align}'
                            }
                        </Formula>

                        Nun kann man erkennen, dass die Eigenkapitalrendite bei konstantem Fremdkapitalzins linear vom Verschuldungsgrad abhängt.
                        In der Theorie heißt das, wenn wir beliebig viel Fremdkapital zu gleichbleibenden Konditionen aufnehmen könnten und wir mehr Geld erwirtschaften als wir Zinsen zurückzahlen müssten (<MathJax.Node formula={'r_G>r_F'} inline/>), könnten wir die Eigenkapitalrendite beliebig erhöhen.
                        Wir nehmen an, dass der Fremdkapitalzins <MathJax.Node formula={'r_F'} inline/> vorgegeben und daher sicher ist.
                        Die Gesamtkapitalrendite <MathJax.Node formula={'r_G'} inline/> und daher auch die verbundene Eigenkapitalrendite <MathJax.Node formula={'r_E'} inline/> sind jedoch unsicher.
                        
                        Daher betrachten wir den Erwartungswert der Renditen.
                        
                        Damit stellt man die Leverage-Gleichung auf:
                        <MathJax.Node formula={'\\operatorname{E}(r_E)=\\operatorname{E}(r_G) + (\\operatorname{E}(r_G)-r_F)\\cdot\\text{VG}'}/>
                        Das Leverage-Risiko ist dann die Varianz der Eigenkapitalrendite:
                        <Formula>
                            {
                                '\\begin{align}' +
                                '\\operatorname{Var}(r_E)&=\\operatorname{Var}(r_G+(r_G-r_F)\\text{VG})' + '\\\\' +
                                '&=\\operatorname{Var}(r_G+r_G\\text{VG}-r_F\\text{VG})' + '\\\\' +
                                '&=\\operatorname{Var}(r_G+r_G\\text{VG})' + '\\\\' +
                                '&=\\operatorname{Var}(r_G(1+\\text{VG}))' + '\\\\' +
                                '&=(1+\\text{VG})^2\\operatorname{Var}(r_G)' +
                                '\\end{align}'
                            }
                        </Formula>
                        Oder auch als Standardabweichung <MathJax.Node formula={'\\sigma_{r_E}=\\sqrt{\\operatorname{Var}(r_E)}'} inline/> mit <MathJax.Node formula={'\\sigma_{r_G}=\\sqrt{\\operatorname{Var}(r_G)}'} inline/>.
                        <MathJax.Node formula={'\\sigma_{r_E}=(1+\\text{VG})\\cdot\\sigma_{r_G}=\\underbrace{\\sigma_{r_G}}_{\\text{Geschäftsrisiko}}+\\underbrace{\\text{VG}\\cdot\\sigma_{r_G}}_{\\text{Kapitalstrukturrisiko}}'}/>
                        Wenn man sich also Geld leiht, sollte man beachten, dass das Risiko nicht linear mitwächst.
                    </BlogEntry>
                    {
                        /**
                         * 
                         * Korrelationskoeffizienten sind Winkel
                         */
                    }
                    <BlogEntry id="korr-winkel" title="Korrelationskoeffizienten und Winkel" subtitle="09.03.2020 - Tim David Brachwitz">
                        In diesem Post versuche ich zu zeigen, dass Winkel aus der linearen Algebra und der Korrelationskoeffizient aus der Stochastik quasi dasselbe mathematische Konzept inne haben.
                        In der linearen Algebra werden Winkel zwischen Vektoren mithilfe von Skalarprodukten und deren induzierten Normen beschrieben.
                        Seien <Inline>{'u'}</Inline> und <Inline>{'v'}</Inline> zwei Vektoren aus <Inline>{'\\mathbb{R}^2'}</Inline>.
                        Dann ist der Winkel <Inline>{'\\theta'}</Inline> dieser beiden Vektoren mit der Formel
                        <Formula>
                            {'\\cos(\\theta) = \\frac{u * v}{\\lvert u\\rvert\\cdot\\lvert v\\rvert}'}
                        </Formula> berechenbar, wobei <Inline>{'\\lvert u\\rvert'}</Inline> den Betrag, also die Länge eines Vektors und <Inline>{'*'}</Inline> das Skalarprodukt darstellt.

                        Seien konkret <Inline>{'u=\\begin{bmatrix}1\\\\5\\end{bmatrix}'}</Inline> und <Inline>{'v=\\begin{bmatrix}3\\\\7\\end{bmatrix}'}</Inline>
                        <div>
                            <div className="flex justify-center">
                                <img className="w-64" src="https://tdbr.de/blog/winkel.png" alt="Vektoren"/>
                            </div>
                        </div>

                        Wir rechnen einfach mal den Winkel zwischen diesen beiden Vektoren aus.

                        <Formula>
                            {
                                '\\begin{align}' +
                                '\\cos{\\theta}&=\\frac{1\\cdot3+5\\cdot7}{\\sqrt{1^2+5^2}\\sqrt{3^2+7^2}}' + '\\\\' +
                                '\\cos{\\theta}&=\\frac{38}{\\sqrt{26}\\sqrt{58}}' + '\\\\' +
                                '\\theta&=\\arccos\\left(\\frac{38}{\\sqrt{26}\\sqrt{58}}\\right) \\approx 11.9^\\circ' + '\\\\' +
                                
                                '\\end{align}'
                            }
                        </Formula>

                        Das Skalarprodukt ist übrigens wie folgt definiert:
                        <Formula>
                            {
                                'u * v = u_1 \\cdot v_1 + u_2 \\cdot v_2'
                            }
                        </Formula>
                        Also für <Inline>{'u=\\begin{bmatrix}1\\\\5\\end{bmatrix}'}</Inline> und <Inline>{'v=\\begin{bmatrix}3\\\\7\\end{bmatrix}'}</Inline>.
                        Produkt der ersten Zeile plus Produkt der zweiten Zeile. <Inline>{'1\\cdot3+5\\cdot7'}</Inline>.

                        <Sep/>
                        Für allgemeine Vektoren und deren Winkel führt man ein allgemeines Skalarprodukt definiert werden.
                        Man bezeichnet es mit dieser Notation.
                        <Formula>
                            {'\\langle u\, v\\rangle'}
                        </Formula>

                        Jedes Skalarprodukt induziert gleichzeitig eine Norm.
                        Die Norm berechnet uns die Länge des Vektors. Dazu nehmen wir einfach die Wurzel aus dem Skalarprodukt mit sich selber.

                        <Formula>
                            {'\\lVert v\\rVert:=\\sqrt{\\langle v\, v\\rangle}'}
                        </Formula>

                        Dann ist ein allgemeiner Winkel durch die Gleichung
                        <Formula>
                            {'\\cos(\\theta) = \\frac{\\langle u, v\\rangle}{\\lVert u\\rVert \\lVert v\\rVert}'}
                        </Formula>
                        
                        definiert.
                        <Sep/>
                        Jetzt kommt ein wenig Wahrscheinlichkeitstheorie dazu.

                        Seien <Inline>{'X'}</Inline> und <Inline>{'Y'}</Inline> zwei Zufallsvariablen, dann ist der Korrelationskoeffizient definiert als
                        <Formula>
                            {'\\rho := \\frac{\\operatorname{Cov}(X, Y)}{\\sqrt{\\operatorname{Var}(X)\\operatorname{Var}(Y)}}'}
                        </Formula>
                        Führen wir nun ein konkretes Skalarprodukt für Zufallsvariablen ein.
                        <Formula>
                            {'\\langle X, Y\\rangle:=\\operatorname{E}(XY)'}
                        </Formula>
                        <Formula>
                            {'\\langle X, X\\rangle=\\operatorname{E}(X^2)=\\lVert X\\rVert^2'}
                        </Formula>

                        <Sep/>
                        Seien <Inline>{'X'}</Inline> und <Inline>{'Y'}</Inline> zentriert, also <Inline>{'\\operatorname{E}(X)=0'}</Inline> und <Inline>{'\\operatorname{E}(Y)=0'}</Inline>. Dann stellt sich heraus, dass
                        <Formula>
                            {'\\operatorname{Cov}(X, Y)=\\operatorname{E}(XY)-\\underbrace{\\operatorname{E}(X)\\operatorname{E}(Y)}_{=0}=\\langle X, Y\\rangle'}
                        </Formula> gilt.
                        Das heißt, dass das Skalarprodukt und die Kovarianz von zwei zentrierten Zufallsvariablen übereinstimmen.
                        Die Kovarianz einer Zufallsvariable mit sich selbst ist die Varianz
                        <Formula>
                            {'\\operatorname{Var}(X)=\\operatorname{E}(X^2)-\\underbrace{\\operatorname{E}(X)^2}_{=0}=\\langle X, X\\rangle=\\lVert X\\rVert^2'}
                        </Formula>

                        Mit diesem Wissen können wir den Korrelationskoeffizienten in folgender allgemeinen Form schreiben.
                        <Formula>
                            {'\\rho = \\frac{\\langle X, Y\\rangle}{\\sqrt{\\langle X, X\\rangle\\langle Y, Y\\rangle}} = \\frac{\\langle X, Y\\rangle}{\\sqrt{\\lVert X\\rVert^2\\lVert Y\\rVert^2}} = \\frac{\\langle X, Y\\rangle}{\\lVert X\\rVert\\lVert Y\\rVert}'}
                        </Formula>

                        <Inline>{'\\arccos(\\rho)'}</Inline> kann man als Winkel zwischen <Inline>{'X'}</Inline> und <Inline>{'Y'}</Inline> interpretieren.
                        Zufallsvariablen sind daher genau dann unkorreliert, wenn sie orthogonal aufeinanderstehen.

                        Daher findet man in der Literatur auch die Schreibweise <Inline>{'X\\perp Y'}</Inline> als Zeichen für Unkorreliertheit oder auch Unabhängigkeit.
                        Wir haben damit die geometrische Anschauung von Vektoren und deren Winkel aus der linearen Algebra auf Zufallsvariablen und Korrelation übertragen.
                            
                        <Sep/>
                        Zum Schluss können wir noch einsehen, dass die Rechenregel für zwei unkorrelierte, also senkrecht aufeinanderstehende, Zufallsvariablen dem Satz des Pythagoras entspricht.

                        <Formula>
                            {
                                '\\begin{align}' +
                                '\\operatorname{Var}(X + Y) &= \\operatorname{Var}(X) &+ \\operatorname{Var}(Y)' + '\\\\' +
                                '\\lVert X+Y\\rVert^2 &= \\lVert X\\rVert^2 &+ \\lVert Y\\rVert^2' + '\\\\' +
                                'c^2&=a^2&+b^2' +
                                '\\end{align}'
                            }
                        </Formula>
                        <Sep/>
                        Wir können noch einen letzten Schritt machen und die Annahme der Unkorreliertheit bzw. der Orthogonalität fallen lassen.

                        Für beliebige Zugallsvariablen ist
                        <Formula>
                                {'\\operatorname{Var}(X + Y) = \\operatorname{Var}(X) + \\operatorname{Var}(Y) + 2\\operatorname{Cov}(X,Y)'}
                        </Formula> also der Kosinussatz

                        <Formula>
                                {'c^2=a^2+b^2-2ab\\cos(\\theta)'}
                        </Formula>

                        Wir können <Inline>{'\\operatorname{Cov}(X,Y)'}</Inline> schreiben als <Inline>{'\\rho\\sqrt{\\operatorname{Var}(X)\\operatorname{Var}(Y)}'}</Inline>.
                        
                        <Formula>
                            {
                                '\\begin{align}' +
                                '\\operatorname{Var}(X + Y) &= \\operatorname{Var}(X) &+ \\operatorname{Var}(Y) &+ 2\\operatorname{Cov}(X,Y)' + '\\\\' +
                                '\\sigma_{X+Y}^2 &= \\sigma_X^2 &+ \\sigma_Y^2 &+ 2 \\sigma_x \\sigma_Y \\rho' + '\\\\' +
                                'c^2&=a^2&+b^2&-2ab\\cos(\\theta)' +
                                '\\end{align}'
                            }
                        </Formula>

                    </BlogEntry>

                    </MathJax.Provider>
                </div>
                <div className="p-3 m-3">
                    
                    <Link className="no-underline text-teal-800 font-bold hover:text-teal-700" to="/"><i className="fas fa-backward"></i> Zurück zur Startseite</Link>
                </div>
            </div>
        )
    }
}
